// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  benutzermanagement: 'https://benutzer.alpha.sanakey-portal.de',
  recaptchaSiteKey: '6LcmelMUAAAAAHbtcLEnMtIfxTI3hDHR3QWULEGL',
  protocol: 'https',
  erlaubeTestEingaben: true,
  MATOMO_SITE_ID: 5,
  difa_url: 'https://urogister-admin.alpha.sanakey-portal.de',
  patienten_selbst_einschreibung_url: 'https://versorgung.alpha.sanakey-portal.de',
  datenschutzbestimmungen: 'https://www.sanakey.de/sanakey-portal/datenschutz',
  nutzungsbedingungen: 'https://www.sanakey.de/sanakey-portal/nutzungsbedingungen',
  impressum: 'https://www.sanakey.de/sanakey-portal/impressum',
  version_datenschutz: 2,
  version_agb: 2,
  show_dummy_data_buttons: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
