import {Injectable, ViewChild} from '@angular/core';
import {NgbToast} from '@ng-bootstrap/ng-bootstrap';

export interface ToastConfig {
    header: string;
    body: string;
    toastType: string;
    delay?: number;
    callback: () => void;
}

@Injectable({
    providedIn: 'root'
})
export class AppToastService {
    toasts: ToastConfig[] = [];

    show(header: string,
         body: string,
         toastType: 'info' | 'success' | 'warning' | 'danger' | 'interactive' = 'info',
         delay = 5000,
         callback?: () => void) {
        this.toasts.push({header, body, toastType, delay, callback});
    }

    remove(toast) {
        this.toasts = this.toasts.filter(t => t !== toast);
    }

    constructor() {
    }
}
