import {Component, OnInit, ViewChild} from '@angular/core';
import {AppToastService} from '../app-toast.service';
import {NgbToast} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'san-app-toast',
  templateUrl: './app-toast.component.html',
  styleUrls: ['./app-toast.component.scss']
})
export class AppToastComponent implements OnInit {
  @ViewChild('toast') ngbToast: NgbToast | undefined;
  toastService: AppToastService;

  constructor(toastService: AppToastService) {
    this.toastService = toastService;
  }

  ngOnInit(): void {
  }

  hide() {
    this.ngbToast.hide();
  }

  proceed(callback: () => void = () => {}) {
    callback();
    this.hide();
  }

}
