<ngb-toast #toast
        *ngFor="let toast of toastService.toasts" [ngClass]="toast.toastType"
        [header]="toast.header" [autohide]="toast.toastType !== 'interactive'" [delay]="toast.delay"
        (hidden)="toastService.remove(toast)"
>
  {{toast.body}}
  <ng-container *ngIf="toast.toastType === 'interactive'">
    <form class="mt-1">
      <div class="input-group">
        <button class="btn btn-primary" (click)="proceed(toast.callback)">
          Fortfahren
        </button>
        <button class="btn btn-primary" (click)="hide()">
          Abbrechen
        </button>
      </div>
    </form>
  </ng-container>
</ngb-toast>
